





























































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  VehicleListFilterModel,
  Vehicle,
  VehiclePaginatedResult,
} from "@/store/models/registration/registration";
import {
  MetaModule as metaStore,
  VehicleModule as vehicleStore,
} from "@/store/modules";
import { VehicleOwnerList } from "@/store/models/meta";
import { AdminRouter } from "@/utils/routePathContsant";
import ListTable from "@/views/registrations/vehicle/allVehicleListTable.vue";
import helper from "@/utils/helpers";
import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

@Component({
  components: {
    ListTable,
  },
})
export default class VehicleClass extends Vue {
  qVehicle: any[] = [];
  vehicleList: Vehicle[] = [];
  districtName: any = [];

  tab: string = "Submitted";
  vehicleListByApproval: VehiclePaginatedResult = {} as VehiclePaginatedResult;
  options1: VehicleOwnerList[] = [];

  filter: VehicleListFilterModel = {
    number: "",
    ownername: "",
    approvalStatus: "",
    page: null,
    page_size: null,
  };

  status = [
    {
      value: "Submitted",
      label: "Submitted",
    },
    {
      value: "Physical Verification",
      label: "Physical Verification",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Reapplied",
      label: "Reapplied",
    },
    {
      value: "Approved",
      label: "Approved",
    },
  ];

  async handleClick(tab: any) {
    this.tab = tab.name;
    this.filter.approvalStatus = this.tab;
    this.filter.page = 1;
    await vehicleStore.loadAllVehicle(this.filter);
    this.vehicleList = vehicleStore.vehicleOnlyList;
    this.vehicleListByApproval = vehicleStore.paginatedVehicleList;
  }

  get vehicleCount() {
    return vehicleStore.vehicleCount;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtL;
  }

  async created() {
    let vm = this;
    const id = vm.$route.query["vehicle_owner"];
    vehicleStore.getVehicleCount();
    metaStore.loadAllVehicleOwner().then(() => {
      this.options1 = metaStore.vehicleOwnerList;
    });

    this.filter.page = 1;
    this.filter.approvalStatus = "Submitted";
    await vehicleStore.loadAllVehicle(this.filter);
    this.vehicleList = vehicleStore.vehicleOnlyList;
    this.vehicleListByApproval = vehicleStore.paginatedVehicleList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.VehicleRegister + "?id=" + id);
  }

  async handlePageChange(val: number) {
    this.filter.approvalStatus = this.tab;
    this.filter.page = val;
    await vehicleStore.loadAllVehicle(this.filter);
    this.vehicleList = vehicleStore.vehicleOnlyList;
    this.vehicleListByApproval = vehicleStore.paginatedVehicleList;
  }

  async filterVehicle() {
    this.filter = {
      number: this.filter.number,
      ownername: this.filter.ownername,
      approvalStatus: this.filter.approvalStatus,
      page: 1,
    };
    await vehicleStore.loadAllVehicle(this.filter);
    this.vehicleList = vehicleStore.vehicleOnlyList;
    this.vehicleListByApproval = vehicleStore.paginatedVehicleList;
  }

  vehicleOwnerRemoteMethod(query: any) {
    if (query !== "") {
      console.log(query);
      setTimeout(() => {
        this.options1 = metaStore.vehicleOwnerList.filter((item) => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.options1 = metaStore.vehicleOwnerList;
    }
  }

  async clearFilter() {
    this.filter = {
      number: "",
      ownername: "",
      approvalStatus: "Submitted",
      page: 1,
      page_size: null,
    };
    await vehicleStore.loadAllVehicle(this.filter);
    this.vehicleList = vehicleStore.vehicleOnlyList;
    this.vehicleListByApproval = vehicleStore.paginatedVehicleList;
  }

  submit(e: any) {
    vehicleStore.createVehicle();
  }

  async downloadExcel(e: any) {
    const action = Action.vehicleListCSV;
    const sheet_path = await getApi(action);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
