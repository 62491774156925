import { render, staticRenderFns } from "./allVehicleList.vue?vue&type=template&id=544b0638&scoped=true&"
import script from "./allVehicleList.vue?vue&type=script&lang=ts&"
export * from "./allVehicleList.vue?vue&type=script&lang=ts&"
import style0 from "./allVehicleList.vue?vue&type=style&index=0&id=544b0638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544b0638",
  null
  
)

export default component.exports